import React from "react";
import { Route } from "react-router-dom";
import Routes from "components/TopLoadingBar";
import AdminSignIn from "pages/Authenticate/Admin/SignIn";
import NotAuthGuard from "middleware/not.auth.guard";
import adminRoutes from "routes/admin.route";
import PageNotFound from 'pages/PageNotFound';

function App() {
  return (
      <Routes>
         <Route exact path="/" element={
               <NotAuthGuard>
               <AdminSignIn />
            </NotAuthGuard>
          } />
         {adminRoutes}
         <Route path="*" element={< PageNotFound /> } />
      </Routes>
  );
}

export default App;
